import React from "react";
import { useTranslation } from 'react-i18next';

export default function Convenience() {
  const { t } = useTranslation();

  return (
    <div className="convenience-div">
      <h3 className="convenience-head">{t("elantra.convenience1")}</h3>
      <p className="convenience-par">
      {t("elantra.convenience2")}
      </p>
      <img
        src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-stay-connected-world-original.jpg"
        alt=""
      />

      <div className="module-parth">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-bluetooth-multiconnection-original.jpg"
            alt=""
          />
          <h3>{t("elantra.convenience3")}</h3>
          <p>
          {t("elantra.convenience4")}
          </p>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-wireless-smartphone-charger-original.jpg"
            alt=""
          />
          <h3>{t("elantra.convenience5")}</h3>
          <p>
          {t("elantra.convenience6")}
          </p>
        </div>
      </div>
      <div className="elantra-conv-sec">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-sideview-mirrors-original.jpg"
            alt=""
          />
          <h3>{t("elantra.convenience7")}</h3>
          <p>
          {t("elantra.convenience8")}          </p>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-electronic-parking-brake-original.jpg"
            alt=""
          />
          <h3>{t("elantra.convenience9")}</h3>
        </div>
      </div>
      <div className="elantra-conv-sec">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-manual-air-conditioner-original.jpg"
            alt=""
          />
          <h3>{t("elantra.convenience10")}</h3>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/convenience/elantra-cn7-convenience-dual-full-auto-air-conditioner-original.jpg"
            alt=""
          />
          <h3>{t("elantra.convenience11")}</h3>
        </div>
      </div>
    </div>
  );
}
