import React, { useState } from "react";
// import FrontView from "./FrontView";

function Exterior() {
  const [hidden, setHidden] = useState(true);

  const showFrontView = () => {
    setHidden(false);
  };
  return (
    <div className="staria-ext-div">
      <div className="technology-parth">
        <h1 className="technology-header">
          Новое слово в автомобильном дизайне.
        </h1>
        <p className="paragraph-text">
          STARIA LUXE открывает новую главу в истории многофункционального
          транспорта. Невозможно оторвать взгляд от футуристичного дизайна, в
          основе которого — концепция роскошного интерьера для отдыха.
          Футуристичный силуэт, а также простота и элегантность стиля хай-тек во
          внешнем облике STARIA олицетворяют новейшие технологии, делающие
          автомобиль более удобным, безопасным и комфортным.
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/premium/staria-us4-design-in-a-new-direction-pc.jpg"
          alt="sonata-i"
        />
      </div>
    </div>
  );
}

export default Exterior;
