import React from "react";
import { useTranslation } from "react-i18next";

export default function Highlights() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("sonata.higlights1")}</h1>
        <p className="paragraph-text">
        {t("sonata.higlights2")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/cars/sonata-dn8-2023/performance/dn8-drive-with-n-line-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">{t("sonata.higlights3")}</h1>
        <p className="paragraph-text">
        {t("sonata.higlights4")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/cars/sonata-dn8-2023/nline/dn8-power-and-precision-meet-style-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("sonata.higlights5")}</h1>
        <p className="paragraph-text">
        {t("sonata.higlights6")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/highlights/sonata-dn8-highlights-a-step-closer-to-perfection-pc.jpg"
          alt="sonata-i"
        />
      </div>

      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">{t("sonata.higlights7")}.</h1>
        <p className="paragraph-text">
        {t("sonata.higlights8")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/highlights/sonata-dn8-highlights-sound-that-embraces-you-pc.jpg"
          alt="sonata-i"
        />
      </div>
    </div>
  );
}
