import React from "react";
import {
  Container,
  Nav,
  Navbar,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import LanguageIcon from "@mui/icons-material/Language";
import Logo from "../../assets/images/logo.png";
import { useTranslation } from 'react-i18next';

export default function  Header() {
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) =>
  {
    i18n.changeLanguage(lang.target.name)
  }

  return (
    <>
      <Navbar bg="" expand="lg" className="navbar-header">
        <Container>
          <Navbar.Brand href="/">
            <img src={Logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home">{t("models")}</Nav.Link>
              <Nav.Link href="/history">{t("history")}</Nav.Link>
              <Nav.Link href="#link">{t("service")}</Nav.Link>
            </Nav>
            <DropdownButton id="dropdown-item-button" title={<LanguageIcon />}>
              <Dropdown.Item onClick={changeLanguageHandler} as="button" name="ru">
                ru
              </Dropdown.Item>
              {/* <Dropdown.Item onClick={changeLanguageHandler} as="button" name="en">
                en
              </Dropdown.Item> */}
              <Dropdown.Item onClick={changeLanguageHandler} as="button" name="am">
                am
              </Dropdown.Item>
            </DropdownButton>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}