import React from "react";
import { useTranslation } from "react-i18next";

export default function Interier() {
  const { t } = useTranslation();

  return (
    <div className="sonata-exterior">
      <div className="second-parth-sonat">
        <div>
          {/* <h2 className="exterier-header">Особенности интерьера </h2>
      <p>
        Высокое качество изображения, основанное на практичности и
        стабильности являются базовыми в новой Sonata.
      </p> */}
        </div>
      </div>
      <div className="first-parth-sonata">
        <div className="tit-wrap">
          <h1>{t("sonata.interier1")}</h1>
          <p>{t("sonata.interier2")}</p>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/interior/sonata-dn8-design-interior-accordion-arrive-releaxed-pc.jpg"
            alt=""
          />
        </div>
        <div className="tit-wrap">
          <h3 className="tit-wrap-header">{t("sonata.interier3")}</h3>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/interior/sonata-dn8-design-interior-accordion-premium-relaxion-seat-pc.jpg"
            alt="inter"
          />
        </div>

        <div className="max-comf">
          <h2 className="max-comf-header">{t("sonata.interier4")}</h2>
          <p>{t("sonata.interier5")}</p>
          <div>
            <img
              alt="comf-img"
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/interior/sonata-dn8-design-interior-accordion-cluster-navigation-original-v1.jpg"
            />
          </div>
          <h3 className="display-part">{t("sonata.interier6")}</h3>
          <p>{t("sonata.interier7")}</p>
        </div>
      </div>
    </div>
  );
}
