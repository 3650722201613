import React from "react";
import CountUp from "react-countup";
import { useTranslation } from 'react-i18next';

const Performance = () => {
    const { t } = useTranslation();

  return (
    <div>
      <div className="elantra-performance-sec">
        <h2>{t("sonata.performance1")}</h2>
        <p>
        </p>
        <img
        style={{width: '100%'}}
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/cars/sonata-dn8-2023/performance/dn8-sonata-performance-kv-pc.jpg"
          alt=""
        />
      </div>
      <div>
        <h2 className="engine">{t("engine")}</h2>
        <div className="engine-parth-sonta">
          <div className="rpm-part">
            <span className="engin-span">Gasoline 2.5T-GDi
              </span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span >
                  {" "}
                  <CountUp
                    start={130.0}
                    end={290.0}
                    duration={2.1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={16.01}
                    end={43}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kgf.m
                </span>
              </div>
            </div>
          </div>
          <div className="rpm-part">
            <span className="engin-span">Gasoline 2.5 GDi</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={101.01}
                    end={123.0}
                    duration={0.1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={10.01}
                    end={17.70}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kg.m
                </span>
              </div>
            </div>
          </div>
          <div className="rpm-part">
            <span className="engin-span">HEV 2.0 GDi
</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={100.5}
                    end={152}
                    duration={1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={10.01}
                    end={19.2}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kg.m
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;
