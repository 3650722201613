import React from "react";
import "../Styles/pageStyles.css";
import Highlights from "./Highlights";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./Sonata.css";
import Interier from "./Interier";
import Convenience from "./Convenience";
import { useTranslation } from "react-i18next";
import Performance from "./Performance";

export default function Sonata() {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <img
          className="page-main-img"
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/cars/sonata-dn8-2023/highlights/dn8-sonata-2023-highlights-kv-pc.jpg"
          alt="sonata"
        />
        <div className=" second-navs">
          <Tabs
            defaultActiveKey="higlights"
            id="justify-tab-example"
            className="mb-3 second-navbar"
            justify
          >
            <Tab eventKey="higlights" title={t("higlights")}>
              <Highlights />
            </Tab>
            <Tab eventKey="interier" title={t("interier")}>
              <Interier />
            </Tab>
            <Tab eventKey="performance" title={t("performance")}>
              <Performance/>
            </Tab>
            <Tab eventKey="convenience" title={t("convenience")}>
              <Convenience />
            </Tab>
          </Tabs>
        </div>
        <div className="notify-text">
          <p>{t("info-sec")}</p>
        </div>
      </div>
    </div>
  );
}
