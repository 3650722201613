import React from "react";
import FooterLogo from "../../assets/images/home-footer-hyudai-logo.png";
import "./footer.css";
import Stack from "@mui/material/Stack";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTranslation } from 'react-i18next';


export default function Footer() {
  const { t } = useTranslation();

  return (
    <div className="footer-wrap">
      <div className="container footer-cont">
        <div>
          <img src={FooterLogo} alt="" />
          <p> {t("address1")} {t("address")}</p>
          <p> {t("tel")} {t("tel1")}</p>
          <p> {t("email")} {t("email1")} </p>
        </div>
        <div>
          <p>{t("hyndai-info")}</p>
          <a href="/history">{t("history")}</a>
        </div>

        <div className="media-parth">
          <p>{t("onas")}</p>

          <Stack direction="row" alignItems="center" spacing={4}>
            <InstagramIcon fontSize="large" sx={{ color: "#E1306C" }} />
            <FacebookIcon color="primary" fontSize="large" />
          </Stack>
        </div>
      </div>
    </div>
  );
}
