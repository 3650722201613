import React from "react";
import { useTranslation } from "react-i18next";

const Charging = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("ioniq5.charging1")}</h1>
        <p className="ioniq-par ">{t("ioniq5.charging2")}</p>
        <video width="900" controls muted loop autoPlay={true}>
          <source
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/charging/ioniq5-ultra-charging.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("ioniq5.charging3")}</h1>
        <img
          className="charging-img"
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/charging/ioniq5-ne-charging-v2l-function-pc.jpg"
          alt="sonata-i"
        />
        <h1 className="technology-header">{t("ioniq5.charging4")}</h1>
        <p className="ioniq-par ">{t("ioniq5.charging5")}</p>
      </div>
      <div className="technology-parth ioniq-tec-part">
        <img
          className="charging-img"
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/charging/ioniq5-ne-charging-charge-from-inside-pc.png"
          alt="sonata-i"
        />
        <h1 className="technology-header">{t("ioniq5.charging6")}</h1>
        <p className="ioniq-par ">{t("ioniq5.charging7")}</p>
      </div>
    </div>
  );
};

export default Charging;
