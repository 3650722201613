import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Highlights from "./Highlights";
import Exterior from "./Exterior";
import "./Staria.css";
import Convenience from "./Convenience";
import { useTranslation } from "react-i18next";

export default function Staria() {
  const { t } = useTranslation();

  return (
    <div>
      <img
        className="page-main-img"
        src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/mpv/staria-full-page/highlights/staria-us4-highlights-kv-pc.jpg"
        alt="sonata"
      />
      <div className=" second-navs">
        <Tabs
          defaultActiveKey="higlights"
          id="justify-tab-example"
          className="mb-3 second-navbar"
          justify
        >
          <Tab eventKey="higlights" title={t("higlights")}>
              <Highlights />
            </Tab>
            <Tab eventKey="performance" title={t("exterier")}>
              <Exterior/>
            </Tab>
            <Tab eventKey="convenience" title={t("convenience")}>
              <Convenience />
            </Tab>   
        </Tabs>
      </div>
    </div>
  );
}
