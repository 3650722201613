import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Highlights from "./Highlights";
import "./SantaFe.css";
import Interier from "./Interier";
import Convenience from "./Convenience";
import { useTranslation } from "react-i18next";
import Performance from "./Performance";

export default function SantaFe() {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <div>
          <img
            className="page-main-img"
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/highlights/mx5-santafe-2023-highlights-kv-pc.jpg"
            alt="sonata"
          />
          <div className=" second-navs">
            <Tabs
              defaultActiveKey="higlights"
              id="justify-tab-example"
              className="mb-3 second-navbar"
              justify
            >
              <Tab eventKey="higlights" title={t("higlights")}>
              <Highlights />
            </Tab>
            <Tab eventKey="interier" title={t("interier")}>
              <Interier />
            </Tab>
            <Tab eventKey="performance" title={t("performance")}>
              <Performance/>
            </Tab>
            <Tab eventKey="convenience" title={t("convenience")}>
              <Convenience />
            </Tab>
            </Tabs>
            <div className="notify-text">
              <p>
              <p>{t("info-sec")}</p>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
