import Home from "./Components/Home/Home";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Sonata from "./Components/pages/Sonata/Sonata";
import Elantra from "./Components/pages/Elantra/Elantra";
import History from "./Components/History/History";
import SantaFe from "./Components/pages/SantaFe/SantaFe";
import Tucson from "./Components/pages/Tucson/Tucson";
import Staria from "./Components/pages/Staria/Staria";
import Ioniq from "./Components/pages/Ioniq/Ioniq";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="sonata" element={<Sonata />} />
            <Route path="santafe" element={<SantaFe/>}/>
            <Route path="elantra" element={<Elantra />} />
            <Route path="history" element={<History />} />
            <Route path="tucson" element={<Tucson/>} />
            <Route path="staria" element={<Staria/>} />
            <Route path="ioniq" element={<Ioniq/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
