import React from "react";
import { useTranslation } from "react-i18next";

export default function Highlights() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("santafe.higlights1")}</h1>
        <p className="paragraph-text">
        {t("santafe.higlights2")}
        </p>
        <video width="900" controls loop autoPlay={true}>
          <source
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/design/video/santafe-2023-design-video-wide-tailgate-pc.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">{t("santafe.higlights3")}.</h1>
        <p className="paragraph-text">
         {t("santafe.higlights4")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/design/santafe-2023-design-front-view-wall-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("santafe.higlights5")}</h1>
        <p className="paragraph-text">
        {t("santafe.higlights6")}
        </p>
        <video width="900" controls loop autoPlay={true}>
          <source
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/design/video/santafe-2023-design-panoramic-curved-display-pc.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">{t("santafe.higlights7")}</h1>
        <p className="paragraph-text">
        {t("santafe.higlights8")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/design/santafe-2023-design-woman-in-santafe-pc.jpg"
          alt="sonata-i"
        />
      </div>
    </div>
  );
}
