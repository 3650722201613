import React from "react";
import { useTranslation } from "react-i18next";

export default function Convenience() {
  const { t } = useTranslation();

  return (
    <div className="convenience-div">
      <h3 className="convenience-head">{t("sonata.convenience1")}</h3>
      <p className="convenience-par">
      {t("sonata.convenience2")}
      </p>
      <img
        src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/convenience/sonata-dn8-convenience-10.25-inch-navigation-pc.jpg"
        alt=""
      />
      <p className="convenience-head">
      {t("sonata.convenience3")}
      </p>

      <div className="module-parth">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/convenience/sonata-dn8-convenience-bluetooth-multi-pairing-original.jpg"
            alt=""
          />
          <h3>{t("sonata.convenience4")}</h3>
          <p>
          {t("sonata.convenience5")}
          </p>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/convenience/sonata-dn8-convenience-bose-premium-sound-system-original.jpg"
            alt=""
          />
          <h3>{t("sonata.convenience6")}</h3>
          <p>
          {t("sonata.convenience7")}
          </p>
        </div>
      </div>
      <div className="module-parth">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/convenience/sonata-dn8-convenience-wireless-smartphone-charging-system-original.jpg"
            alt=""
          />
          <h3>{t("sonata.convenience8")}</h3>
          <p>
          {t("sonata.convenience9")}
          </p>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/sonata-2019/convenience/sonata-dn8-convenience-bluetooth-multi-pairing-original.jpg"
            alt=""
          />
          <h3>{t("sonata.convenience10")}</h3>
          <p>
          {t("sonata.convenience11")}
          </p>
        </div>
      </div>
    </div>
  );
}
