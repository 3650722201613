import React from "react";
import { useTranslation } from "react-i18next";

const Sustainability = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("ioniq5.sustainability1")}</h1>
        <p className="ioniq-par">{t("ioniq5.sustainability2")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/sustainability/ioniq5-ne-sustainability-eco-friendly-lifestyle-pc.jpg"
          alt="sonata-i"
        />
      </div>

      <div className="grid-area">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/sustainability/ioniq5-sustainability-bio-paint-original.jpg"
            alt=""
          />
        </div>
        <div className="text-area">
          <h3>{t("ioniq5.sustainability3")}</h3>
          <p>{t("ioniq5.sustainability4")}</p>
        </div>
      </div>
      <div className="grid-area-sec">
        <div className="text-area">
          <h3>{t("ioniq5.sustainability5")}</h3>
          <p>{t("ioniq5.sustainability6")}</p>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/sustainability/ioniq5-sustainability-eco-friendly-leather-original.jpg"
            alt=""
          />
        </div>
      </div>
      <div className="grid-area">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/sustainability/ioniq5-sustainability-eco-friendly-fabric-original.jpg"
            alt=""
          />
        </div>
        <div className="text-area">
          <h3>{t("ioniq5.sustainability7")}</h3>
          <p>{t("ioniq5.sustainability8")}</p>
        </div>
      </div>
    </div>
  );
};

export default Sustainability;
