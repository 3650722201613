import React from "react";
import { useTranslation } from "react-i18next";

export default function Convenience() {
  const { t } = useTranslation();

  return (
    <div className="convenience-div">
      <h3 className="convenience-head">{t("staria.convenience1")}</h3>
      <p className="convenience-par">{t("staria.convenience2")}</p>
      <img
        src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-convenience-everyday-is-liberation-day-pc.jpg"
        alt=""
      />

      <div className="module-parth staria-module-parth">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-convenience-long-sliding-seats-original.jpg"
            alt=""
          />
          <h3>{t("staria.convenience8")}</h3>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-convenience-usb-outlet-original.jpg"
            alt=""
          />
          <h3>{t("staria.convenience9")}</h3>
        </div>
      </div>
      <div className="staria-conv">
        <div className="staria-conv-sec-par">
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-convenience-8-inch-display-audio-original.jpg"
              alt=""
            />
            <h3>{t("staria.convenience10")}</h3>
          </div>
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-convenience-1025-inch-cluster-original.jpg"
              alt=""
            />
            <h3>{t("staria.convenience11")}</h3>
          </div>
        </div>
        <div className="staria-conv-sec-par">
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-full-auto-air-conditioning-system-original.jpg"
              alt=""
            />
            <h3>{t("staria.convenience12")}</h3>
          </div>
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/convenience/staria-us4-premium-bose-premium-sound-system-original.jpg"
              alt=""
            />
            <h3>{t("staria.convenience13")}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
