import React from "react";
import { useTranslation } from "react-i18next";

export default function Convenience() {
  const { t } = useTranslation();
  return (
    <div className="convenience-div">
      <h3 className="convenience-head">
      {t("tucson.convenience1")}
      </h3>
      <p className="convenience-par">
      {t("tucson.convenience2")}

      </p>
      <img
        src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/convenience/tucson-nx4-fmc-convenience-your-digital-journey-starts-here-pc.jpg"
        alt=""
      />

      <div className="tucson-conv-parth">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/convenience/tucson-nx4-fmc-convenience-10dot25-inch-color-tft-lcd-cluster-original.jpg"
            alt=""
          />
          <h3>      {t("tucson.convenience3")}</h3>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/convenience/tucson-nx4-fmc-convenience-4dot2-inch-color-tft-lcd-cluster-original.jpg"
            alt=""
          />
          <h3>      {t("tucson.convenience4")}
</h3>
        </div>
      </div>
      <div className="tucson-conv-parth">
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/convenience/tucson-nx4-fmc-convenience-10dot25-inch-smart-navigation-original.jpg"
            alt=""
          />
          <h3>
          {t("tucson.convenience5")}

          </h3>
        </div>
        <div>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/convenience/tucson-nx4-fmc-convenience-full-auto-air-conditioning-system-original.jpg"
            alt=""
          />
          <h3>      {t("tucson.convenience6")}
</h3>
        </div>
      </div>
      <div className="tucson-end-part-conv">
        <h3 className="convenience-head">
        {t("tucson.convenience7")}
        </h3>
        <p className="convenience-par">
        {t("tucson.convenience8")}

        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/convenience/tucson-nx4-fmc-convenience-your-digital-journey-starts-here-pc.jpg"
          alt=""
        />
      </div>
    </div>
  );
}
