import React from "react";
import { useTranslation } from "react-i18next";


export default function Interier() {
  const { t } = useTranslation();

  return (
    <div className="sonata-exterior">
      <div className="second-parth-tucson">
        <div>
          <h2 className="exterier-header">Особенности интерьера </h2>
        </div>
      </div>
      <div className="first-parth-sonata">
        <div className="tit-wrap">
          <h1>{t("tucson.higlights1")}</h1>

          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/interior/tucson-nx4-fmc-design-interior-accordion-live-for-the-moment-pc.jpg"
            alt=""
          />
        </div>
        <div className="tucson-interier-parth">
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/interior/tucson-nx4-fmc-design-interior-accordion-10dot25-inch-display-and-full-touch-center-fascia-original.jpg"
              alt=""
            />
            <p>
            {t("tucson.higlights2")}
            </p>
          </div>
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/interior/tucson-nx4-fmc-design-interior-accordion-10dot25-inch-open-type-cluster-original.jpg"
              alt=""
            />
            <p>{t("tucson.higlights3")}</p>
          </div>
        </div>
        <div className="tucson-interier-parth">
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/interior/tucson-nx4-fmc-design-interior-accordion-button-type-sbw-original.jpg"
              alt=""
            />
            <p>
            {t("tucson.higlights4")}

            </p>
          </div>
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/interior/tucson-nx4-fmc-design-interior-accordion-Ambient-mood-lamp-original.jpg"
              alt=""
            />
            <p>{t("tucson.higlights5")}
</p>
          </div>
        </div>
      </div>
    </div>
  );
}
