import React from "react";
import { useTranslation } from "react-i18next";

export default function Convenience() {
  const { t } = useTranslation();

  return (
    <div className="">
      <div className="section-media">
        <div className="section-media-sec">
          <div>
            <h3>{t("santafe.convenience1")}</h3>
          </div>
          <div className="conditioner-sec">
            <h4>{t("santafe.convenience2")}</h4>
            <p className="sect-med-p">
            {t("santafe.convenience3")}
            </p>
          </div>
        </div>
        <img src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/convenience/santafe-2023-convenience-conditioner-pc.jpg" />
      </div>
      <div className="wirless-chargeng">
        <h2>{t("santafe.convenience4")}</h2>
        <p>{t("santafe.convenience5")}</p>
        <img src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/convenience/santafe-2023-convenience-dual-wireless-charging-pc.jpg" />
      </div>
      <div className="full-access-arr">
        <h2>{t("santafe.convenience6")}</h2>
        <p>{t("santafe.convenience7")}</p>
    
        <video width="900" controls loop autoPlay={true}>
          <source
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/convenience/video/santafe-2023-convenience-multi-console-pc.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div className="section-media digital-hiligt">
        <div className="section-media-sec">
          <div>
            <h3>{t("santafe.convenience8")}
</h3>
          </div>
          <div className="conditioner-sec">
            <h4>{t("santafe.convenience9")}</h4>
            <p className="sect-med-p">
            {t("santafe.convenience10")}            </p>
          </div>
        </div>
        <img src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/convenience/santafe-2023-convenience-ota-pc.jpg" />
        <div className="fing-sec">
          <img src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/convenience/santafe-2023-convenience-key-free-pc.jpg"/>
          <img src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/convenience/santafe-2023-convenience-fingertip-pc.jpg"/>

        </div>
      </div>
    </div>
  );
}
