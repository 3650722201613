import React from "react";
import { useTranslation } from "react-i18next";

const Technology = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("ioniq5.technology1")}</h1>
        <p className="ioniq-par ">{t("ioniq5.technology2")}</p>
        <img
          className="charging-img"
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/technology/ioniq5-ne-technology-featuring-breathe-taking-design-pc.jpg "
          alt="sonata-i"
        />
      </div>
      <div className=" tec-sec">
        <h1 className="technology-header">{t("ioniq5.technology7")}</h1>
        <div className="grid-area">
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/technology/ioniq5-ne-technology-long-range-battery.png"
              alt=""
            />
          </div>
          <div className="text-area">
            <h3>{t("ioniq5.technology3")}</h3>
            <p>{t("ioniq5.technology4")}</p>
            <h3>{t("ioniq5.technology5")}</h3>
            <p>{t("ioniq5.technology6")}</p>
          </div>
        </div>
      </div>
      <div className=" tec-thir">
        <h1 className="technology-header">{t("ioniq5.technology8")}</h1>

        <div className="grid-area-tec">
          <div>
            <img
              src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/technology/ioniq5-ne-technology-dstandard-battery.png"
              alt=""
            />
          </div>
          <div className="text-area">
            <h3>{t("ioniq5.technology3")}</h3>
            <p>{t("ioniq5.technology9")}</p>
            <h3>{t("ioniq5.technology5")}</h3>
            <p>{t("ioniq5.technology10")}</p>
          </div>
        </div>
      </div>
      {/* <div className="technology-parth">
        <h1 className="technology-header">{t("ioniq5.technology11")}</h1>
        <video width="900" controls muted loop autoPlay={true}>
          <source
            src="https://www.youtube.com/21de846d-ac14-4660-8f24-1eaac66590b8"
            type="video/mp4"
          />
        </video>
      </div> */}
    </div>
  );
};

export default Technology;
