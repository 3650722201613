import React from "react";
import CountUp from "react-countup";
import { useTranslation } from 'react-i18next';

const Performance = () => {
    const { t } = useTranslation();

  return (
    <div>
      <div className="elantra-performance-sec">
        <h2>{t("elantra.performance1")}</h2>
        <p>
        {t("elantra.performance2")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/cars/elantra-cn7/2023/performance/elantra-performance-driving-pc.jpg"
          alt=""
        />
      </div>
      <div>
        <h2 className="engine">{t("engine")}</h2>
        <div className="engine-parth">
          <div className="rpm-part">
            <span>Smartstream G2.0 Gasoline Engine</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={135.01}
                    end={149.0}
                    duration={2.1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={16.01}
                    end={18.3}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kg.m
                </span>
              </div>
            </div>
          </div>
          <div className="rpm-part">
            <span>Smartstream G1.6 Gasoline Engine</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={101.01}
                    end={123.0}
                    duration={0.1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={10.01}
                    end={17.70}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kg.m
                </span>
              </div>
            </div>
          </div>
          <div className="rpm-part">
            <span>Gamma G1.6 Gasoline Engine</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={100.5}
                    end={127.5}
                    duration={1.1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={10.01}
                    end={15.77}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kg.m
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Performance;
