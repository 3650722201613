import React from "react";
import { useTranslation } from "react-i18next";

export default function Highlights() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("staria.higlights1")}</h1>
        <p className="paragraph-text">{t("staria.higlights2")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/highlights/staria-us4-fmc-highlights-step-into-the-space-pc.png"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header"> {t("staria.higlights3")}</h1>
        <h3>
          {" "}
          <b> {t("staria.higlights4")}</b>
        </h3>
        <p className="paragraph-text">
          <br />
          {t("staria.higlights5")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/highlights/staria-us4-highlights-premium-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth">
        <h1 className="technology-header"> {t("staria.higlights6")}</h1>
        <p className="paragraph-text">{t("staria.higlights7")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/highlights/staria-us4-highlights-wagon-pc.jpg"
          alt="sonata-i"
        />
      </div>

      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header"> {t("staria.higlights8")}</h1>
        <h3>
          <b>{t("staria.higlights9")}</b>
        </h3>
        <p className="paragraph-text">{t("staria.higlights10")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/staria/highlights/staria-us4-highlights-van-pc.png"
          alt="sonata-i"
        />
      </div>
    </div>
  );
}
