import React from "react";
import { useTranslation } from "react-i18next";

export default function Highlights() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("tucson.higlights1")}</h1>
        <p className="paragraph-text">{t("tucson.higlights2")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/highlights/tucson-nx4-fmc-highlights-step-in-stand-out-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">{t("tucson.higlights3")} </h1>
        <p className="paragraph-text">{t("tucson.higlights4")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/highlights/tucson-nx4-fmc-highlights-design-that-thrilling-and-timeless-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("tucson.higlights5")} </h1>
        <p className="paragraph-text">{t("tucson.higlights6")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/highlights/tucson-nx4-fmc-highlights-4th-generation-tucson-poised-to-set-new-record-pc.jpg"
          alt="sonata-i"
        />
      </div>

      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">{t("tucson.higlights7")}</h1>
        <p className="paragraph-text">{t("tucson.higlights8")}</p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/tucson-2021/highlights/tucson-nx4-fmc-highlights-reason-to-believe-pc.jpg"
          alt="sonata-i"
        />
      </div>
    </div>
  );
}
