import React from "react";
import "./history.css";
import { useTranslation } from 'react-i18next';

export default function History() {
  const { t } = useTranslation();

  return (
    <div className="hystory-parth">
      <div>
        <img src="https://www.hyundai.com/content/dam/hyundai/kz/ru/images/cont/20220420/ELANTRA-P.jpg" alt="" />
      </div>
      <div className="container">
        <h2 className="hystory-header">{t("history1")}</h2>
        <p className="hystory-paragraph">
        {t("history2")}
        </p>
        <p className="history-header-sec">
          <b>
          {t("history3")}
          </b>
        </p>
        <img src="https://www.hyundai.com/content/dam/hyundai/kz/ru/images/cont/20220420/STORY.jpg" alt=""/>
        <p>
        {t("history4")}
        </p>
      </div>
    </div>
  );
}
