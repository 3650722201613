import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

import translationAM from './locales/am/translation.json';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';

// the translations
const resources = {
  am: {
    translation: translationAM
  },
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  }
};

i18n
.use(initReactI18next)
.init({
  resources,
  fallbackLng: 'am',
  debug: true,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  }
});