import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import "./models.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from "react-router-dom";
import data from "../../utils/slider.json";
import { sliderSettings } from "../../utils/common";
import { useTranslation } from 'react-i18next';

export default function Models() {
  const { t } = useTranslation();

  return (
    <>
      <p className="models-par">{t("models")}</p>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        {...sliderSettings}
        navigation
        autoplay
      >
        {data.map((card, index) => (
          <SwiperSlide key={index}>
            <div className="flexColStart r-card">
              <Link to={card.link}>
                <img src={card.image}  className="slider-img" alt="models"/>
              </Link>
              <span className="swiper-models">{card.detail}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
