import React from 'react'
import { useTranslation } from 'react-i18next';
import "./Ioniq.css"
const Highlights = () => {
    const { t,  } = useTranslation();

  return (
    <div>
        <div className="technology-parth">
        <h1 className="technology-header">{t("ioniq5.higlights1")}</h1>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/ioniq5-the-winner-of-2022-world-car-awards-title.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header-ioniq">
        {t("ioniq5.higlights2")}    
        </h1>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/ioniq5-wcoty-youtube-thumbnail-pc.jpg"
          alt="sonata-i"
        />
      </div>

      <div className="technology-parth">
        <h1 className="technology-headerioniq">{t("ioniq5.higlights3")}</h1>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/space/ioniq5-ne-space-spacious-customizable-interior-pc.jpg"
          alt="sonata-i"
        />
      </div>
      
    </div>
  )
}

export default Highlights
