import React from "react";
import { useTranslation } from 'react-i18next';

export default function Highlights() {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("elantra.technology-header1")}</h1>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/kz/ru/images/find-a-car/pip/Elantra/video-poster-cn7.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">
        {t("elantra.technology-header2")}
        </h1>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/highlights/elantra-cn7-highlights-make-your-move-no-one-else-will-original.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth">
        <h1 className="technology-header">{t("elantra.technology1")}</h1>
        <p className="paragraph-text">
        {t("elantra.technology2")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/highlights/elantra-cn7-highlights-the-answer-is-you-pc.jpg"
          alt="sonata-i"
        />
      </div>

      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">
        {t("elantra.technology3")}
        </h1>
        <p className="paragraph-text">
        {t("elantra.technology4")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/highlights/elantra-cn7-highlights-question-the-old-rules-pc.jpg"
          alt="sonata-i"
        />
      </div>
      <div className="technology-parth technology-sec-part">
        <h1 className="technology-header">
        {t("elantra.technology5")}
        </h1>
        <p className="paragraph-text">
        {t("elantra.technology6")}
        </p>
        <img
          src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/highlights/elantra-cn7-highlights-bring-the-limelight-to-you-pc.jpg"
          alt="sonata-i"
        />
      </div>
    </div>
  );
}
