import React from 'react'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from 'react-i18next';
import Highlights from './Highlights';
import Sustainability from './Sustainability';
import Charging from './Charging';
import Technology from './Technology';

const Ioniq = () => {
  const { t } = useTranslation();

  return (
    <div>
      <img
        className="page-main-img"
        src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/highlights/ioniq5-ne-highlight-kv-pc.jpg"
        alt="elantra"
      />
      <div className=" second-navs">
        <Tabs
          defaultActiveKey="higlights"
          id="justify-tab-example"
          className="mb-3 second-navbar"
          justify
        >
          <Tab eventKey="higlights" title={t("higlights")} >
            <Highlights/>
          </Tab>
          <Tab eventKey="interier" title={t("sustainability")}>
            <Sustainability/>
          </Tab>
          <Tab eventKey="chargind" title={t("charging")}>
            <Charging/>
          </Tab>
          <Tab eventKey="technology" title={t("technology")}>
            <Technology/>
          </Tab>
       
        </Tabs>
        <div className="notify-text">
              <p>
              {t("info-sec")}
              </p>
            </div>
      </div>
    </div>
  )
}

export default Ioniq
