import React from "react";
import { useTranslation } from "react-i18next";

export default function Interier() {
  const { t } = useTranslation();

  return (
    <div className="sonata-exterior">
      <div className="second-parth-santafe">
        <div>
          <h2 className="exterier-header">Особенности интерьера </h2>
        </div>
      </div>
      <div className="first-parth-sonata">
        <div className="tit-wrap">
          <h1>{t("santafe.interier1")}</h1>
          <p>
          {t("santafe.interier2")}
          </p>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/safety/mx5-santafe-safety-kv-pc.jpg"
            alt=""
          />
        </div>
        <div className="tit-wrap">
          <h3 className="tit-wrap-header">
          {t("santafe.interier3")}
          </h3>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/design/santafe-2023-design-row-seats-and-space-pc.jpg"
            alt="inter"
          />
        </div>
      </div>
    </div>
  );
}
