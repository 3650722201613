import React from "react";
import HomeImgCarousel from "../ImageCarusels/HomeImgCarousel";
import Models from "../Models/Models";

export default function Home() {
  return (
    <div>
      <HomeImgCarousel />
      <Models />
    </div>
  );
}
