import React from "react";
import CountUp from "react-countup";
import { useTranslation } from 'react-i18next';

const Performance = () => {
    const { t } = useTranslation();

  return (
    <div>
      {/* <div className="elantra-performance-sec">
        <h2>{t("sonata.performance1")}</h2>
        <p>
        </p>
        <img
        style={{width: '100%'}}
          src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/performance/mx5-santafe-performance-kv-pc.jpg"
          alt=""
        />
      </div> */}
      <div>
        <h2 className="engine">{t("engine")}</h2>
        <div className="engine-parth-santafe">
          <div className="rpm-part">
            <span className="engin-span">Gasoline 2.5T-GDi
              </span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span >
                  {" "}
                  <CountUp
                    start={130.0}
                    end={281.0}
                    duration={1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={0}
                    end={43}
                    duration={1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kgf.m
                </span>
              </div>
            </div>
          </div>
          <div className="rpm-part">
            <span className="engin-span">Gasoline 2.5 GDi</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={100}
                    end={194}
                    duration={1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={10.01}
                    end={25.1}
                    duration={0.1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kgf.m
                </span>
              </div>
            </div>
          </div>
          <div className="rpm-part">
            <span className="engin-span">Gasoline 1.6T HEV

</span>
            <div className="engin-sec">
              <div className="power-part">
                <p>Max Power </p>
                <span>
                  {" "}
                  <CountUp
                    start={100}
                    end={180}
                    duration={1}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  ps
                </span>
              </div>
              <div className="power-part">
                <p>Max Tirque </p>
                <span>
                  <CountUp
                    start={10.1}
                    end={27.0}
                    duration={0.3}
                    separator=" "
                    decimals={1}
                    decimal=","
                  />{" "}
                  kgf.m
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="unleash">
        <h2>{t("santafe.performance1")}</h2>
        <video width="900" controls loop autoPlay={true}>
          <source
            src="https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/santa-fe-mx5-2023/performance/video/santafe-2023-performance-unleash-your-potential-pc.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

export default Performance;
