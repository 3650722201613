import React from 'react'
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import "./imageCarousel.css"
export default function HomeImgCarousel() {
        const images = [
        "https://www.hyundai.com/content/dam/hyundai/ww/en/images/vehicles/vehicles-kv-2023-pc.jpg",
        "https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/cars/sonata-dn8-2023/performance/dn8-sonata-performance-kv-pc.jpg",
        "https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/mpv/staria-full-page/highlights/staria-us4-highlights-kv-pc.jpg",
        "https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/suv/tucson-nx4/tucson-nx4-n-line-quater-view-kv-pc.jpg",
        "https://www.hyundai.com/content/dam/hyundai/ww/en/images/find-a-car/pip/eco/ioniq5/full-pages/highlights/ioniq5-ne-highlight-kv-pc.jpg",
    ];

    return (
        <>
        <Slide >
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[0]})` }}>
                <h2 class="slider-par">SANTA FE</h2>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[1]})` }}>
                <h2 class="slider-par">SONATA</h2>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[2]})` }}>
                <h2 class="slider-par">STARIA</h2>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[3]})` }}>
                <h2 class="slider-par">TUCSON</h2>
                </div>
            </div>
            <div className="each-slide-effect">
                <div style={{ 'backgroundImage': `url(${images[4]})` }}>
                <h2 class="slider-par">IONIQ 5</h2>
                </div>
            </div>
        </Slide>
        </>
    );
}
