import React from "react";
import { useTranslation } from 'react-i18next';

export default function Interier() {
  const { t } = useTranslation();

  return (
    <div className="sonata-exterior">
      <div className="second-parth-elantra">
        <div>
          <h2 className="exterier-header">{t("elantra.interier1")} </h2>
          
        </div>
      </div>
      <div className="first-parth-sonata">
        <div className="tit-wrap">
          <h1>{t("elantra.interier2")}</h1>
          <p>
          {t("elantra.interier3")}
          </p>
          <img
            src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/interior/elantra-cn7-design-most-sensuous-pc.jpg"
            alt=""
          />
        </div>

        <div className="max-comf">
          <div>
            <img
              alt="comf-img"
              src="https://www.hyundai.com/content/dam/hyundai/template_en/en/images/find-a-car/pip/elantra-2020/interior/elantra-cn7-design-immersive-interface-original.jpg"
            />
          </div>
          <h2 className="max-comf-header">{t("elantra.interier4")}</h2>
          <p>
          {t("elantra.interier5")}
          </p>
        </div>
      </div>
    </div>
  );
}
